<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12">
                <TrunkIPNDListCard :porta-account-data="data" :loading="loading"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import TrunkIPNDListCard from "../../components/cards/Trunk/TrunkIPNDListCard.vue";
import filterMixin from '../../mixins/filterMixin';
import GlobalFetchStoreMixin from "../../mixins/GlobalFetchStoreMixin";
export default {
    name: 'TrunkIPNDList',
  mixins: [GlobalFetchStoreMixin],
    // data: () => ({
    // }),
    components: {
      TrunkIPNDListCard,
    },
  computed: {
    data() {
      if (
        this.$store.state.data.trunk &&
        this.$store.state.data.trunk.i_account ===
        parseInt(this.$route.params.trunkId)
      ) {
        return this.$store.state.data.trunk;
      }
      return {};
    },
    loading() {
      return this.fetchStoreLoading || this.busy;
    },
  },
};
</script>
