<template>
    <ComplexDialog
        :title="'New '+label(true)"
        v-model="visible"
        :step="step"
        :loading="loading || busy"
        :step-end="5"
        :step-next-disabled="(step===1 && ((type == 'sip-trunk' && !form.password) || !form.valid)) ||
                             (step===2 && (!address)) ||
                             (step===3 && !plan)"
        @step-next="next"
        @step-back="back"
        @step-end="finish"
        :step-end-text="'Go To '+label(true)"
        :step-next-text="step===4 ? 'Create New '+label(false) : 'Next'"
        :max-width="1000"
    >
      <v-window v-model="step">
        <v-window-item :value="1">
          <p class="subtitle-1 text--primary">
            {{ label(false) }} Details
          </p>
          <p>Please configure details for the new {{ label(true) }}.</p>
          <v-form v-model="form.valid">
            <v-row no-gutters>
              <v-col cols="12" sm="6" v-if="type == 'sip-trunk'" >
                <TrunkIDField
                  v-model="form.id"
                  label="Trunk ID *"
                  hint="Trunk ID is auto generated"
                  outlined
                />
              </v-col>
              <v-col cols="12" sm="6" v-if="type == 'sip-trunk'">
                <PasswordField
                    v-model="form.password"
                    label="Password *"
                    hint="Must be at least 8 characters and contain a letter and a number"
                    outlined
                    auto-generate
                />
              </v-col>
              <v-col cols="12" sm="6"  v-if="type == 'hosted-extension'">
                <v-text-field
                    v-model="form.extension_number"
                    label="Extension Number *"
                    :counter="3"
                    :rules="[ruleCheckExtensionNumber]"
                    hint="The number to dial that is linked to this extension. Must be unique between this customer's extensions. This cannot be changed."
                    outlined
                />
              </v-col>
              <v-col cols="12" sm="6"  v-if="type == 'hosted-extension'"></v-col>

              <v-col cols="12" sm="6"  v-if="type == 'ring-group'">
                <v-text-field
                    v-model="form.ring_group_number"
                    label="Ring Group Number *"
                    :counter="3"
                    :rules="[ruleCheckRingGroupNumber]"
                    hint="Reference number for the Ring Group. Must be unique between this customer's Ring Group. This cannot be changed."
                    outlined
                />
              </v-col>
              <v-col cols="12" sm="6"  v-if="type == 'ring-group' && false">
                <v-text-field
                    v-model="form.ring_group_name"
                    label="Name *"
                    :counter="41"
                    :rules="[ruleCheckRingGroupName]"
                    hint="Reference name"
                    outlined
                />
              </v-col>

              <v-col cols="12" sm="6"  v-if="type == 'ring-group'">
                <v-select
                    outlined
                    label="Sequence *"
                    v-model="form.ring_group_sequence"
                    :disabled="busy"
                    :items="ringGroupSelects.sequence"
                    item-text="option"
                    item-value="value"
                    hint="Ringing strategy"
                    :menu-props="{ top: true, offsetY: true }"
                    :rules="rules.required"

                />
              </v-col>

              <v-col cols="12" sm="6"  v-if="type == 'ring-group'">
                <v-select
                    outlined
                    label="Caller ID *"
                    v-model="form.ring_group_callerId"
                    :disabled="busy"
                    :items="ringGroupSelects.callerId"
                    item-text="option"
                    item-value="value"
                    hint="How the receiver sees the caller's ID"
                    :menu-props="{ top: true, offsetY: true }"
                    :rules="rules.required"
                />
              </v-col>

              <v-col cols="12" v-if="type == 'ring-group'">
                  <v-checkbox
                      v-model="form.call_pickup_allowed"
                      label="Call Pickup Allowed"
                      value="Y"
                  ></v-checkbox>
                </v-col>


              <v-col cols="12" sm="6">
                <v-text-field
                    v-model="form.description"
                    label="Description"
                    :counter="41"
                    :rules="rules.description"
                    hint="This description will be shown in the portal (optional)"
                    outlined
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                    v-model="form.cont1"
                    label="Name / Billing Reference"
                    :counter="120"
                    :rules="rules.cont1"
                    hint="This will be shown on the customer's bill (optional)"
                    outlined
                />
              </v-col>
            </v-row>
            <v-row no-gutters v-if="type == 'fax-to-mail'">
              <v-col cols="12" sm="6">
                <v-text-field
                    v-model="form.email"
                    label="Email to send faxes*"
                    :rules="rules.email"
                    outlined
                />
              </v-col>
            </v-row>

            <p v-if="type == 'hosted-extension'" class="subtitle-1 text--primary">
              Owner Details
            </p>
            <p v-if="type == 'hosted-extension'">Please configure details of the owner of this extension.</p>
            <v-row no-gutters v-if="type == 'hosted-extension'">
              <v-col cols="12" sm="6">
                <v-text-field
                    v-model="form.first_name"
                    label="First Name *"
                    outlined
                    :rules="rules.first_name"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                    v-model="form.last_name"
                    label="Last Name *"
                    outlined
                    :rules="rules.last_name"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                    v-model="form.email"
                    label="Email *"
                    :rules="rules.email"
                    outlined
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                    v-model="form.phone1"
                    label="Contact phone/mobile Number *"
                    :rules="rules.phone1"
                    outlined
                />
              </v-col>
            </v-row>
          </v-form>
        </v-window-item>
        <v-window-item :value="2">
          <p class="subtitle-1 text--primary">
            Service Address
          </p>
          <p>Please enter the address where this {{label(true)}} is located. This will be used in the <a href="https://www.acma.gov.au/give-information-ipnd" target="_blank">Integrated Public Number Database (IPND)</a> which is the Emergency Services Address.</p>
          <ExistingAddressDetailsForm
              v-model="address"
              :customer-id="customerId ? customerId : form.i_customer"
          />
        </v-window-item>
        <v-window-item :value="3">
          <p class="subtitle-1 text--primary">
            Plan Selection
          </p>
          <p>Please choose the plan to be applied to the new {{ label(true) }}.</p>
          <TrunkPlanForm
              v-if="visible && type == 'sip-trunk'"
              v-model="plan"
              :show-nfr="customerData.nfr_limit > 0"
          />
          <ExtensionPlanForm
              v-if="visible && type == 'hosted-extension'"
              v-model="plan"
          />
          <FaxToMailPlanForm
            v-if="visible && type == 'fax-to-mail'"
            v-model="plan"
            :newProduct="true"
          />
          <RingGroupPlanForm
              v-if="visible && type == 'ring-group'"
              v-model="plan"
              :newProduct="true"
          />
          <AutoAttendantPlanForm
              v-if="visible && type == 'auto-attendant'"
              v-model="plan"
              :newProduct="true"
          />
        </v-window-item>
        <v-window-item :value="4">
          <p class="subtitle-1 text--primary">
            Review
          </p>
          <p>Please review the below information to ensure it is correct before creating the new {{ label(true) }}.</p>
          <v-sheet
              :color="'grey' + ($vuetify.theme.isDark ? ' darken-3' : ' lighten-4')"
              class="pa-4 mb-4"
              rounded
              v-if="step===4"
          >
            <v-row dense>
              <v-col cols="4" class="font-weight-bold">
                Customer
              </v-col>
              <v-col cols="8">
                {{customerData.name}} ({{customerId}})
              </v-col>
              </v-row>
            <v-row dense v-if="type=='hosted-extension'">
              <v-col cols="4" class="font-weight-bold">
                Extension ID
              </v-col>
              <v-col cols="8">
                bcex{{customerId.toString()+form.extension_number.toString()}}
              </v-col>
            </v-row>
            <v-row dense v-if="type=='ring-group'">
              <v-col cols="4" class="font-weight-bold">
                Ring Group ID
              </v-col>
              <v-col cols="8">
                bcrg{{customerId.toString()+form.ring_group_number.toString()}}
              </v-col>
            </v-row>
            <v-row dense v-if="type=='ring-group'">
              <v-col cols="4" class="font-weight-bold">
                Number
              </v-col>
              <v-col cols="8">
                {{form.ring_group_number}}
              </v-col>
            </v-row>
            <v-row dense v-if="type=='ring-group'">
              <v-col cols="4" class="font-weight-bold">
                Name
              </v-col>
              <v-col cols="8">
                {{form.ring_group_name}}
              </v-col>
            </v-row>
            <v-row dense v-if="type=='ring-group'">
              <v-col cols="4" class="font-weight-bold">
                Sequence
              </v-col>
              <v-col cols="8">
                {{ringGroupSelects.sequence.find(element => element.value == form.ring_group_sequence)? ringGroupSelects.sequence.find(element => element.value == form.ring_group_sequence).option : ''}}
              </v-col>
            </v-row>
            <v-row dense v-if="type=='ring-group'">
              <v-col cols="4" class="font-weight-bold">
                Caller ID
              </v-col>
              <v-col cols="8">
                {{ringGroupSelects.callerId.find(element => element.value == form.ring_group_callerId)? ringGroupSelects.callerId.find(element => element.value == form.ring_group_callerId).option : ''}}
              </v-col>
            </v-row>
            <v-row v-if="form.description" dense>
              <v-col cols="4" class="font-weight-bold">
                Description
              </v-col>
              <v-col cols="8">
                {{form.description}}
              </v-col>
            </v-row>
            <v-row v-if="form.cont1" dense>
              <v-col cols="4" class="font-weight-bold">
                Name / Billing Ref
              </v-col>
              <v-col cols="8">
                {{form.cont1}}
              </v-col>
            </v-row>

            <v-row dense v-if="type=='hosted-extension'">
              <v-col cols="4" class="font-weight-bold">
                Contact Name
              </v-col>
              <v-col cols="8">
                {{form.first_name}} {{form.last_name}}
              </v-col>
            </v-row>

            <v-row dense v-if="type=='hosted-extension'">
              <v-col cols="4" class="font-weight-bold">
                Email
              </v-col>
              <v-col cols="8">
                {{form.email}}
              </v-col>
            </v-row>

            <v-row dense v-if="type=='hosted-extension'">
              <v-col cols="4" class="font-weight-bold">
                Phone Number
              </v-col>
              <v-col cols="8">
                {{form.phone1}}
              </v-col>
            </v-row>

            <v-row v-if="address" dense>
              <v-col cols="4" class="font-weight-bold">
                Service Address
              </v-col>
              <v-col cols="8">
                <AddressText :data="address" />
              </v-col>
            </v-row>
          </v-sheet>
          <TrunkPlanInfoSummary
              v-if="step===4 && type=='sip-trunk'"
              :data="plan"
          />
          <ExtensionPlanInfoSummary
              v-if="step===4 && type=='hosted-extension'"
              :data="plan"
          />
          <RingGroupPlanInfoSummary
              v-if="step===4 && type=='ring-group'"
              :data="plan"
          />
          <FaxToMailPlanInfoSummary
              v-if="step===4 && type=='fax-to-mail'"
              :data="plan"
          />
          <AutoAttendantPlanInfoSummary
              v-if="step===4 && type=='auto-attendant'"
              :data="plan"
          />
        </v-window-item>
        <v-window-item :value="5">
          <v-row no-gutters>
            <v-col cols="12" class="text-center py-4">
              <v-icon size="120" color="success"
              >mdi-check-circle-outline</v-icon
              >
              <p class="text-h6">New {{ label(true) }} created successfully</p>
            </v-col>
          </v-row>

        </v-window-item>
      </v-window>


    </ComplexDialog>
</template>

<script>
import apiMixin from '@/mixins/apiMixin';
import dialogMixin from '@/mixins/dialogMixin';
import ringGroupDataMixin from "../../../mixins/ringGroupDataMixin";
import ComplexDialog from '@/components/dialogs/templates/ComplexDialog';
import PasswordField from '@/components/pieces/PasswordField';
import TrunkPlanForm from "../../pieces/Trunk/TrunkPlanForm";
import ExtensionPlanForm from "../../pieces/Extension/ExtensionPlanForm.vue";
import RingGroupPlanForm from "../../pieces/RingGroup/RingGroupPlanForm";
import FaxToMailPlanForm from "../../pieces/FaxToMail/FaxToMailPlanForm";
import AutoAttendantPlanForm from "../../pieces/AutoAttendant/AutoAttendantPlanForm";
import TrunkIDField from '@/components/pieces/TrunkIDField';
import TrunkPlanInfoSummary from "../../pieces/Trunk/TrunkPlanInfoSummary.vue";
import ExtensionPlanInfoSummary from "../../pieces/Extension/ExtensionPlanInfoSummary";
import RingGroupPlanInfoSummary from '@/components/pieces/RingGroup/RingGroupPlanInfoSummary';
import FaxToMailPlanInfoSummary from "../../pieces/FaxToMail/FaxToMailPlanInfoSummary";
import AutoAttendantPlanInfoSummary from "../../pieces/AutoAttendant/AutoAttendantPlanInfoSummary";
import ExistingAddressDetailsForm from "../../pieces/Forms/ExistingAddressDetailsForm.vue";
import AddressText from '@/components/pieces/Address/AddressText';
import Vue from 'vue';
export default {
  name: 'NewTrunkDialog',
  mixins: [apiMixin, dialogMixin, ringGroupDataMixin],
  components: {
    AutoAttendantPlanForm,AutoAttendantPlanInfoSummary, AddressText, ExistingAddressDetailsForm, TrunkPlanInfoSummary, ExtensionPlanInfoSummary, TrunkIDField, TrunkPlanForm, ExtensionPlanForm, PasswordField, ComplexDialog,
  FaxToMailPlanForm,
  FaxToMailPlanInfoSummary,
  RingGroupPlanForm,
  RingGroupPlanInfoSummary,
  },
  data: () => ({
    step: 1,
    busy: false,
    form: {
      id: null,
      password: null,
      description: null,
      i_customer: null,
      cont1: null,
      valid: false,
      extension_number:null,
      email: null,
      phone1: null,
      first_name: null,
      last_name: null,
      ring_group_number: null,
      //ring_group_name: null,
      ring_group_callerId: 'I',
      ring_group_sequence: null,
      call_pickup_allowed: null,
    },
    email: null,
    address: null,
    plan: null,
    i_account: null,
    customerExtensions:[ ], //used to validate the extension number
    rules: {
      description: [
        (v) =>
            !v || v.length <= 41 ||
            'Description must be 41 characters or less',
      ],
      cont1: [
        (v) =>
            !v || v.length <= 120 ||
            'Name / Billing Reference must be 120 characters or less',
      ],
      first_name: [
        (v) => !!v || 'Required',
        (v) => (!!v && v.length > 1) || 'First name must contain at least 2 characters.'
      ],
      last_name: [
        (v) => !!v || 'Required',
        (v) => (!!v && v.length > 1) || 'Last name must contain at least 2 characters.'
      ],
      email: [
        (v) => !!v || 'Required',
        (v) => /^.+@.+\..+$/.test(v) || 'Invalid email',
      ],
      phone1: [
        (v) => !!v || 'Required',
        (v) => (/^(?:0[2,3,5-9]\d{8})?$/.test(v) || /^(?:04\d{8})?$/.test(v)) || 'Invalid Phone/Mobile number.'
      ],
      required: [
        (v) => !!v || 'Required',
          ]
    }
  }),
  created: function(){
    this.form.extension_number = this.defaultExtensionNumber;

  },
  watch: {
    visible(value) {
      if (value) {
        this.i_account = null;
        if(this.type == 'hosted-extension'){
          this.getCustomerExtensions();
        }else if(this.type == 'ring-group'){
          this.getCustomerRingGroups();
        }
      } else {
        this.reset( );
      }
    },
  },
  methods: {
    reset( ){
      this.step = 1;
      this.form.id = null;
      this.form.password = null;
      this.form.description = null;
      this.form.cont1 = null;
      this.plan = null;
      this.email = null;
      this.address = null;
      this.form.i_customer = null;
      this.form.phone1 = null;
      this.form.first_name = null;
      this.form.last_name = null;
      this.form.email = null;
      this.form.ring_group_number = null;
     //this.form.ring_group_name = null;
      this.form.ring_group_callerId = 'I';
      this.form.ring_group_sequence = null;
      this.call_pickup_allowed = null;
    },
    ruleCheckExtensionNumber(numberIn) {
      let number = numberIn;
      if(numberIn == null) {
       number = this.form.extension_number;
      }

      if (number == null) {
        return 'Required';
      }

      if(isNaN(number)){
        return 'Must be numeric.';
      }

      if (parseInt(number) < 100 || parseInt(number) > 599) {
        return 'Extension Number must be between 100 and 599.';
      }

      if(number % 1 != 0){
        return 'Extension Number must be a whole number.';
      }

      if(number.toString().length != 3){
        return 'Incorrect count of characters. Must be 3 numbers.';
      }

      for(let i = 0; i<this.customerExtensions.length; i++){
        if(parseInt(number) == parseInt(this.customerExtensions[i].id)){
          return 'Extension Number already used by customer.';
        }
      }
      return true;
    },
    ruleCheckRingGroupNumber(numberIn) {
      let number = numberIn;
      if(numberIn == null) {
        number = this.form.ring_group_number;
      }

      if (number == null) {
        return 'Required';
      }

      if(isNaN(number)){
        return 'Must be numeric.';
      }

      if (parseInt(number) < 800 || parseInt(number) > 899) {
        return 'Ring Group Number must be between 800 and 899.';
      }

      for(let i = 0; i<this.customerRingGroups.length; i++){
        if(parseInt(number) == parseInt(this.customerRingGroups[i].id)){
          return 'Ring Group Number already used by customer.';
        }
      }
      return true;
    },
    ruleCheckRingGroupName(nameIn) {
      let name = nameIn;
      if(nameIn == null) {
        name = this.form.ring_group_name;
      }

      if (name == null) {
        return 'Required';
      }

      if (name.length < 3) {
        return 'Name must be between at least 3 characters.';
      }

      for(let i = 0; i<this.customerRingGroups.length; i++){
        if(name == this.customerRingGroups[i].name){
          return 'Ring Group Name \''+name+'\' already used by customer.';
        }
      }
      return true;
    },
    setInitialExtensionNumber( ){
      if(this.defaultExtensionNumber != null){
        this.form.extension_number = this.defaultExtensionNumber;
        return;
      }
      for(let i = 100;i<600; i++){
        if(this.ruleCheckExtensionNumber(i) === true){
          this.form.extension_number = i;
          return;
        }
      }
      this.form.extension_number = null;
    },
    async getCustomerExtensions( ){
      this.busy = true;
      let response = await this.Api.send('get', 'customers/'+this.customerId+'/extensions');
      if (response.success) {
        this.customerExtensions = response.data.data;
      }
      this.setInitialExtensionNumber( );
      this.busy = false;
    },
    setInitialRingGroupNumber( ){
      this.form.ring_group_number = 800;
      for(let i = 800;i<900; i++){
        if(this.ruleCheckRingGroupNumber(i) === true){
          this.form.ring_group_number = i;
          return;
        }
      }
      this.form.ring_group_number = null;
    },
    async getCustomerRingGroups( ){
      this.busy = true;
      let response = await this.Api.send('get', 'customers/'+this.customerId+'/ring-groups');
      if (response.success) {
        this.customerRingGroups = response.data.results;
      }
      this.setInitialRingGroupNumber( );
      this.busy = false;
    },
    async next() {
      if (this.step===4) {
        this.busy = true;
        let params = {
          description: this.form.description,
          cont1: this.form.cont1,
          address_info: this.address,
          i_customer: this.form.i_customer ? this.form.i_customer : this.customerId,
          plan_info: this.plan,
        }
        if(this.type == 'sip-trunk'){
          params.id = this.form.id;
          params.password = this.form.password;
        }else if(this.type == 'hosted-extension'){
          params.extension_number = this.form.extension_number;
          params.firstname = this.form.first_name;
          params.lastname = this.form.last_name;
          params.email = this.form.email;
          params.phone1 = this.form.phone1;
        }else if(this.type == 'ring-group'){
          params.number = this.form.ring_group_number;
          //params.name = this.form.ring_group_name;
          params.sequence = this.form.ring_group_sequence;
          params.callerId = this.form.ring_group_callerId;
          params.call_pickup_allowed = this.form.call_pickup_allowed
        }else if(this.type == 'fax-to-mail'){
          params.email = this.form.email;
        }

        let response = null;
        if(this.type == 'sip-trunk') {
            response = await this.Api.send('post', 'sip-trunks', params);
        }else if(this.type == 'hosted-extension'){
            response = await this.Api.send('post', 'extensions', params);
        }else if(this.type == 'fax-to-mail'){
          response = await this.Api.send('post', 'fax-to-mail', params);
        }else if(this.type == 'ring-group'){
          response = await this.Api.send('post', 'ring-groups', params);
        }else if(this.type == 'auto-attendant'){
          response = await this.Api.send('post', 'auto-attendant-accounts', params);
        }else{
          throw 'Unrecognised type ('+this.type+')';
        }
        if (response.success) {
          this.i_account = response.data.i_account;
          document.dispatchEvent(new CustomEvent('refreshRequested'));
          this.step++;
        }
        this.busy = false;
      } else if(this.step == 1){
        if(this.requiredAddress( )){
          this.step++;
        }else{
          this.step += 2;
        }
      }else{
        this.step++;
      }
    },
    back() {
      if(this.step == 3) {
        if (this.requiredAddress()) {
          this.step--;
        } else {
          this.step -= 2;
        }
      }else {
        this.step--;
      }
    },
    finish() {
      this.visible = false;
      this.$emit('trunk', this.i_account);
    },
    label(full){
      let valueFull = {
        "sip-trunk":"SIP Trunk",
        "hosted-extension":"Hosted Extension",
        "fax-to-mail":"Fax To Mail Account",
        "ring-group":"Ring Group Account",
        "auto-attendant":"Auto Attendant Account",
      };
      let value = {
        "sip-trunk":"Trunk",
        "hosted-extension":"Extension",
        "fax-to-mail":"Fax To Mail",
        "ring-group":"Ring Group",
        "auto-attendant":"Auto Attendant Account",
      };

      if(full){
        return valueFull[this.type];
      }else{
        return value[this.type];
      }
    },
    /*
      Since the updates for the IPND records addresses are no longer linked to the PortaAccount
      This is disabled.
     */
    requiredAddress( ) {
      // if(this.type == 'ring-group'){
      //   return false;
      // }else{
        return false;
      // }
    }
  },
  props: {
    customerId: {
      type: Number,
      default: null
    },
    customerData: {
      type: Object,
      default: null
    },
    type:{
      type: String,
      default: 'sip-trunk',
      validator: function (value) {
        // The value must match one of these strings
        return ['sip-trunk','hosted-extension','fax-to-mail','ring-group','auto-attendant'].indexOf(value) !== -1
      }
    },
    defaultExtensionNumber:{
      type: String,
      default: null,
    }
  },

};
</script>