<template>
    <ComplexDialog
        :title="title"
        v-model="visible"
        :loading="busy"
    >
    <v-window>
      <v-window-item>
        <p class="subtitle-1 text--primary">
          Contact Details
        </p>

        <ContactDetailsForm
            v-model="contact"
            :contactData="contactData"
        />
      </v-window-item>
    </v-window>

        <template v-slot:actions>
            <v-spacer />
            <v-btn
                text
                color="primary"
                :loading="busy"
                @click="save"
                :disabled="(contact == null)"
            >{{contact == null || contact.id == null?'Save New Contact':'Update Contact'}}</v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
    import ContactDetailsForm from "../pieces/Forms/ContactDetailsForm";
    import ComplexDialog from './templates/ComplexDialog';
    import dialogMixin from '../../mixins/dialogMixin';
    import apiMixin from '../../mixins/apiMixin';
    export default {
        name: 'AddContactDialog',
        mixins: [apiMixin, dialogMixin],
        components: {
          ComplexDialog,
          ContactDetailsForm
        },
        data: () => ({
            step: 1,
            note: '',
            flagged: false,
            busy: false,
            contact:null,
            values: {
              titles: ['Dr', 'Miss', 'Mr', 'Mrs', 'Ms']
            },
        }),
        methods: {
            async save() {
                this.busy = true;
                let response;
                let save=this.contact;
                save.salutation = this.contact.title;
                if(this.contact.id == null && this.partnerId != null) {
                  response = await this.Api.send('post', 'partners/' + this.partnerId + '/contact', save);
                }else if(this.contact.id != null) {
                  response = await this.Api.send('put', 'contacts/' + this.contact.id, save);
                }else{
                  console.error('Unhandled customerId/partnerId combination.');
                }
                if (response.success) {
                  if(this.contact.id == null && this.partnerId != null) {
                    this.showGlobalSuccessMessage('New Contact created successfully.');
                  }else{
                    this.showGlobalSuccessMessage('Contact updated successfully.');
                  }
                  document.dispatchEvent(new CustomEvent('refreshRequested'));
                  this.visible = false;
                  this.contact = null;
                }
                this.busy = false;
            }
        },
      watch: {
        // prefill: function (val) {
        //   this.contact = val;
        // },
        // visible: function (value) {
        //   this.contact = this.prefill;
        //   console.log(this.prefill);
        //   this.contact = this.prefill;
        //   console.log(this.contact);
        // },
      },
        props: {
          partnerId: {
              type: Number,
              default: null,
          },
          customerId: {
              type: Number,
              default: null,
          },
          title: {
              type: String,
              default: 'Add New Contact'
          },
          // data: {
          //     type: Object,
          //     default: null
          // },
          contactData: {
            type: Object,
            default: null
          }
        },
      // created() {
      //   console.log(this.prefill);
      //   this.contact = this.prefill;
      //   console.log(this.contact);
      // }
    };
</script>
