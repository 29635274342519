<template>
    <BasicCard
      title="Emergency Services Address (IPND)"
      v-bind:class="{
            'error lighten-4': (!$vuetify.theme.dark && errorIpnd),
            'error darken-4': ($vuetify.theme.dark && errorIpnd),
        }"
      :loading="isBusy"
    >
      <template v-slot:feature>
        <v-icon x-large color="error" v-if="errorIpnd">mdi-alert-circle</v-icon>
      </template>

      <Alert
        v-if="ipndData.is_bc_address && cardType=='trunk'"
        type="error"
        message="Emergency Service address is missing. Please update Address Details."
      />
      <Alert
        v-else-if="!ipndData.confirmed && !ipndData.is_bc_address"
        type="error"
        :message="'Address is unconfirmed.'+(cardType=='trunk'?' Edit and submit this address to confirm.':'')"
      />

      <Alert
        v-if="cardType=='trunk' && ipndData.is_bc_address && $store.state.user.user_type === 'admin'"
        type="error"
        message="ADMIN MSG: This is linked to Leaders Address. This needs attention. "
      />


      <div v-if="ipndData.is_bc_address && cardType=='trunk'">
        <p class="title text--primary">No Emergency Address</p>
        <p>Attend to this immediately.</p>
      </div>
      <div v-else>
        <p class="mb-0"><strong>{{ipndData.label}}</strong></p>
        <p class="title text--primary">
          {{ipndData.address_string}}
        </p>

        <div v-if="false">
          <div v-if="ipndData.hasOwnProperty('customer_name_1') && ipndData.alternate_address_flag == 'F'">Contact Name: {{ ipndData.customer_name_1 }} {{ ipndData.customer_name_2 }}</div>
          <div v-if="ipndData.hasOwnProperty('customer_name_1') && ipndData.alternate_address_flag == 'T'">Owner Name: {{ ipndData.customer_name_1 }} {{ ipndData.customer_name_2 }}</div>
          <div v-if="ipndData.alternate_address_flag == 'T'">Contact Name: {{ ipndData.customer_contact_name_1 }} {{ ipndData.customer_contact_name_2 }}</div>
          <div v-if="ipndData.alternate_address_flag == 'T'">Contact Number: {{ ipndData.customer_contact_nr }}</div>
        </div>

        <div>Contact Name: {{ ipndData.customer_contact_name_1 }} {{ ipndData.customer_contact_name_2 }}</div>
        <div>Contact Number: {{ ipndData.customer_contact_nr }}</div>
      </div>

      <template v-slot:actions>
        <ButtonWithTooltip
          v-if="cardType=='ipnd' && $route.name != 'IPNDDetails' && $route.name != 'PartnerIPNDDetails'"
          text="View"
          flat
          tooltip="View Details"
          icon="mdi-eye"
          :to="{
                name: $store.state.user.isAdmin ? 'IPNDDetails' : 'PartnerIPNDDetails',
                params: {
                    ipndId: ipndData.ids[Object.keys(ipndData.ids)[0]][0],
                    vendorNumber: Object.keys(ipndData.ids)[0]
                },
            }"
        />

        <ButtonWithTooltip
          v-if="cardType == 'trunk'"
          text="Edit Address"
          icon="mdi-pencil"
          :disabled="ipndData.update_permission_error != null"
          :tooltip="ipndData.update_permission_error"
          flat
          @click="editIPNDAddressDialog = true"
        />
      </template>

      <EditTrunkIPNDAddressDialog
        v-if="cardType=='trunk'"
        v-model="editIPNDAddressDialog"
        :porta-account-data="portaAccountData"
        :generic-ipnd-data="ipndData.is_bc_address ? null : ipndData"
      ></EditTrunkIPNDAddressDialog>

    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import EditTrunkIPNDAddressDialog from "../../dialogs/Trunk/EditTrunkIPNDAddressDialog.vue";
import Alert from "../../pieces/Alert.vue";

/**
 * This is a basic version of the TrunkIPNDCard.
 * Useful when we just want to display the IPND details without having to load in extra details.
 */
export default {
    name: 'IPNDCard',
    components: { Alert, BasicCard, ButtonWithTooltip,EditTrunkIPNDAddressDialog },
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      ipndData:{
        type: Object,
        default: null
      },
      portaAccountData:{
        type: Object,
        default:null,
      },
      /*
      Set the card in a different version.
      Either ipnd or trunk.
       */
      cardType:{
        type: String,
      }
    },
    data: () => ({
      busy:false,
      editIPNDAddressDialog: false,
    }),
    methods: {

    },
  computed: {
    isBusy: function( ){
      return this.loading || this.busy;
    },
    errorIpnd: function() {
      if(this.ipndData == null){
        return false;
      }

      if(this.ipndData.is_bc_address){
        if(this.ipndData.i_account != null) {
          return true; //in use should not be linked to default.
        }
        return false;
      }else{
        return !this.ipndData.confirmed
      }
    }
  },
};
</script>
