<template>
    <ComplexDialog
        :title="title"
        v-model="visible"
        :max-width="650"
        :loading="busy"
    >
        <v-window v-model="step" touchless v-if="visible">
            <v-window-item :value="1">
                <p class="subtitle-1 text--primary">
                    Where would you like to move the selected DIDs?
                </p>
                <v-expand-transition>
                    <p v-if="!!customerId && !changeCustomer">
                        <a @click="showCustomerChoice">Click here</a> to transfer to a different customer
                    </p>
                </v-expand-transition>
                <v-expand-transition>
                    <CustomerDropdown
                        v-if="!customerId || changeCustomer"
                        v-model="newCustomerId"
                        @clear="newTrunkId = null"
                        @update="updateCustomer"
                        autofocus
                    />
                </v-expand-transition>
                <v-expand-transition>
                    <div
                        v-if="(customerId && !changeCustomer) || newCustomerId"
                    >
                        <TrunkDropdown
                            v-model="newTrunkId"
                            :customer-id="
                                newCustomerId ? newCustomerId : customerId
                            "
                            :error-messages="
                                trunkId && trunkId === newTrunkId
                                    ? 'The selected DIDs are already assigned to this account'
                                    : ''
                            "
                            @update="updateTrunk"
                            :label="'Account'"
                            init-dropdown
                        />
                    </div>
                </v-expand-transition>
            </v-window-item>
            <v-window-item :value="2">
              <p class="subtitle-1 text--primary">
                Set the Emergency Service Address
              </p>
              <v-row dense>
                <v-col cols="12">
                  <v-simple-table dense>
                    <thead>
                    <tr>
                      <th>
                        <v-checkbox
                          v-model="selectAllCheckbox"
                          @change="toggleSelectAll"
                          :indeterminate="haveASelectedDid && !allDidsSelected"
                        />
                      </th>
                      <th  class="text-center">
                         Phone Number
                      </th>
                      <th class="text-center">
                        <span v-if="!haveASelectedDid">Emergency Service Address</span>
                        <span v-else>
                          <ButtonWithTooltip icon="mdi-format-list-bulleted" color="primary" text="Select" @click="ipndListDialog = true"/> or
                          <ButtonWithTooltip icon="mdi-pencil" color="primary" text="Create" @click="ipndDetailsDialog = true"/>
                          </span>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="didListLocal.length === 0">
                      <td colspan="7" class="text-center">
                        There are no numbers in the list. Step back and make a selection.
                      </td>
                    </tr>
                    <tr
                      v-for="(did, index) in didListLocal"
                      v-bind:key="index"
                      dense
                    >
                      <td class="text-center py-0">
                        <v-checkbox
                          v-if="did.ipnd_reqirement"
                          v-model="did.selected"
                          @click="didCheckClick(did)"
                        />
                      </td>
                      <td class="text-center py-0">
                        {{did.number}}
                      </td>
                      <td class="text-center py-0">
                        <span v-if="selectedIpnds[did.i_did_number] != null">{{selectedIpnds[did.i_did_number].label}}</span>
                        <ButtonWithTooltip
                          v-if="did.ipnd_reqirement"
                          :disabled="haveASelectedDid"
                          icon="mdi-format-list-bulleted"
                          @click="openIpndDialog(did,'list')"
                          color="primary"
                          tooltip="Select Emergency Service Address"
                          flat
                        />
                        <ButtonWithTooltip
                          v-if="did.ipnd_reqirement"
                          :disabled="haveASelectedDid"
                          icon="mdi-pencil"
                          @click="openIpndDialog(did,'create')"
                          color="primary"
                          tooltip="Create Emergency Service Address"
                          flat
                        />
                      </td>
                    </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>

            </v-window-item>
            <v-window-item :value="3">
                <v-row dense v-if="newTrunkId">
                    <v-col cols="12">
                        <p class="subtitle-1 text--primary">
                            <span v-if="!busy && !taskProgress"
                                >Please review the list of
                                {{ didListLocal.length }} DID<span
                                    v-if="didListLocal.length !== 1"
                                    >s</span
                                >
                                to be moved to {{ trunkData ? trunkData.account_type_label : 'Trunk' }} '{{
                                    newTrunkData ? newTrunkData.id : ''
                                }}' (ID
                                {{
                                    newTrunkData ? newTrunkData.i_account : ''
                                }}).</span
                            >
                            <span v-if="busy"
                                >Please wait while the DIDs are moved...</span
                            >
                            <span v-if="!busy && taskProgress"
                                >Operation completed, please check results
                                below.</span
                            >
                        </p>
                        <v-progress-linear
                            rounded
                            height="25"
                            v-if="busy || taskProgress > 0"
                            class="my-4"
                            :value="taskProgress"
                        >
                            <template v-slot:default="{ value }">
                                <strong>{{ Math.ceil(value) }}%</strong>
                            </template>
                        </v-progress-linear>
                        <v-simple-table dense fixed-header height="400">
                            <thead>
                                <tr>
                                    <th>Phone Number</th>
                                    <th v-if="changeCustomer || !customerId">
                                        Existing Customer
                                    </th>
                                    <th v-if="changeCustomer || !customerId">
                                        New Customer
                                    </th>
                                    <th>Emergency Service Address Label</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="item in didListLocal"
                                    v-bind:key="item.number"
                                    dense
                                >
                                    <td>{{ item.number }}</td>
                                    <td v-if="changeCustomer || !customerId">
                                        {{
                                            item.i_customer
                                                ? item.customer_name
                                                : '-'
                                        }}
                                    </td>
                                    <td v-if="changeCustomer || !customerId">
                                        {{
                                            newCustomerData
                                                ? newCustomerData.name
                                                : '-'
                                        }}
                                    </td>
                                  <td>
                                    <span v-if="!item.ipnd_reqirement">N/A</span>
                                    <span v-else-if="selectedIpnds[item.i_did_number] != null">{{selectedIpnds[item.i_did_number].label}}</span>
                                  </td>
                                    <td>
                                        <span v-if="!busy && !taskProgress">
                                            <span
                                                v-if="
                                                    item.i_account ||
                                                    item.i_customer
                                                "
                                                >Move</span
                                            >
                                            <span v-else>Assign</span>
                                            <span v-if="newTrunkId > 0">
                                                DID to trunk '{{
                                                    newTrunkData
                                                        ? newTrunkData.id
                                                        : ''
                                                }}'</span
                                            >
                                            <span v-else-if="item.i_customer">
                                                to customer<span
                                                    v-if="newCustomerData"
                                                >
                                                    '{{
                                                        newCustomerData.id
                                                    }}'</span
                                                ></span
                                            >
                                        </span>
                                        <span v-if="busy || taskProgress">
                                            <v-icon
                                                color="success"
                                                class="mr-2"
                                                v-if="item.success"
                                                >mdi-check-circle</v-icon>
                                            <v-icon
                                                color="warning"
                                                class="mr-2"
                                                v-if="item.error"
                                                >mdi-alert</v-icon>
                                            <v-progress-circular
                                                v-if="item.busy"
                                                class="mr-2"
                                                :width="3"
                                                :size="20"
                                                color="primary"
                                                indeterminate
                                            ></v-progress-circular>
                                          {{ item.status }}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-window-item>
        </v-window>
        <template v-slot:actions>
            <v-btn
                v-if="step === 3 || step === 2"
                text
                :disabled="busy || taskProgress > 0"
                @click="step--"
                >Back</v-btn
            >
            <v-spacer />
            <v-btn
                @click="step++"
                color="primary"
                :disabled="
                    busy ||
                    (step === 1 && !newTrunkId) ||
                    (step === 2 && !newTrunkIpndSatisfied) ||
                    (trunkId && trunkId === newTrunkId)
                "
                v-if="step !== 3"
                text
                >Next</v-btn
            >
            <v-btn
                @click="moveDIDs"
                color="primary"
                :disabled="busy"
                v-if="step === 3 && taskProgress < 100"
                text
                >Move {{ didListLocal.length }} DID<span
                    v-if="didListLocal.length !== 1"
                    >s</span
                ></v-btn
            >
            <v-btn
                @click="visible=false"
                :disabled="busy"
                color="primary"
                tooltip="Close this window"
                v-if="step === 3 && taskProgress === 100"
                text
                >Close</v-btn
            >
        </template>

      <EditTrunkIPNDAddressDialog v-if="newTrunkData != null"
        v-model="ipndDetailsDialog"
        :porta-account-data="newTrunkData"
        :current-ipnd-list="selectableIpndList"
        :return-values="true"
        @selected="selectIpnd"
      />
      <IPNDListSelectDialog v-if="selectableIpndList != null && selectableIpndList.length > 0"
        :ipnd-list="selectableIpndList"
        v-model="ipndListDialog"
        @singleSelected="selectIpnd"
      />
      <SimpleDialog v-else
        v-model="ipndListDialog"
        title="No Emergency Service Addresses"
        :okOnly="true"
      >
        <p>This Trunk/Account has no Emergency Service Addresses to select from. Please create a new Emergency Service Address instead.</p>
      </SimpleDialog>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import CustomerDropdown from '../../pieces/Forms/CustomerDropdown';
import TrunkDropdown from '../../pieces/Forms/TrunkDropdown';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import ButtonWithTooltip from "../../pieces/ButtonWithTooltip.vue";
import IPNDListSelectDialog from "../IPND/IPNDListSelectDialog.vue";
import EditTrunkIPNDAddressDialog from "../Trunk/EditTrunkIPNDAddressDialog.vue";
import SimpleDialog from "../templates/SimpleDialog.vue";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
export default {
    name: 'MoveDIDDialog',
    mixins: [apiMixin, dialogMixin, GlobalHelperMixin],
    components: {
      SimpleDialog,
      EditTrunkIPNDAddressDialog,
      IPNDListSelectDialog,
      ButtonWithTooltip,
      TrunkDropdown,
      CustomerDropdown,
      ComplexDialog,
    },
    data: () => ({
      step: 1,
      busy: false,
      fail: false,
      tasksComplete: 0,
      didListLocal: [],
      newCustomerId: null,
      newTrunkId: null,
      newTrunkData: null,
      changeCustomer: false,
      newCustomerData: null,

      selectedIpnds: {}, //object with the i_did_number mapped to the generic values for the selected IPND.
      selectAllCheckbox: false,
      ipndDetailsDialog:false,
      ipndListDialog:false,
      currentActiveDidIDidNumber:null, //This is the current did is being interacted with. EG if open a dialog we use this varaible to track the callback to the did.

    }),
    methods: {
        reset: function( ){
          this.step = 1;
          this.fail = false;
          this.changeCustomer = false;
          this.newCustomerId = null;
          this.newTrunkId = null;
          this.newTrunkData = null;
          this.didListLocal = [];
          this.tasksComplete = 0;
          this.selectedIpnds = {};
          this.selectAllCheckbox= false;
          this.ipndDetailsDialog = false;
          this.ipndListDialog = false;
          this.currentActiveDidIDidNumber = null;

          //dids are loaded with the .select = true loaded.
          //we revert this here on reset
          this.didListLocal = this.didList; //Note we reassign the values so we do not interfere with the same values passed by the parent.
          let mapped = this.getVnMappedToIpndRequirement( );
          this.didListLocal.forEach((item) => {
            item.selected = false;
            item.ipnd_reqirement = mapped[item.vn];
          });
        },

        openIpndDialog: function(did, type){
          this.currentActiveDidIDidNumber = did.i_did_number;
          if(type == 'list'){
            this.ipndListDialog = true;
          }else if(type == 'create'){
            this.ipndDetailsDialog = true;
          }else{
            console.error('Unrecognised type ('+type+').');
          }
        },
        didCheckClick: function(did){
          if(this.allDidsSelected){
            this.selectAllCheckbox = true;
          }else {
            this.selectAllCheckbox = false;
          }
        },
        selectIpnd: function(ipndValues){
          if(this.haveASelectedDid){
            Object.keys(this.selectedDids).forEach((key) => {
              // this.selectedIpnds[key] = ipndValues;
              this.$set(this.selectedIpnds, key, ipndValues); //make this property in the object reactive.
            });
          }else{
            let active = this.currentActiveDidIDidNumber;
            this.didListLocal.forEach((did)=>{
              if(did.i_did_number == active) {
                // this.selectedIpnds[did.i_did_number] = ipndValues;
                this.$set(this.selectedIpnds, did.i_did_number, ipndValues); //make this property in the object reactive.
                return;
              }
            });
          }
        },
        showCustomerChoice: function () {
            this.newTrunkId = null;
            this.newTrunkData = null;
            this.changeCustomer = true;
        },
        updateTrunk: function (val) {
            this.newTrunkData = val;
        },
        updateCustomer: function (val) {
            this.newCustomerData = val;
        },
        toggleSelectAll() {
          this.didListLocal.forEach((item) => {
            item.selected = this.selectAllCheckbox;
          });
        },
        async moveDID(did) {
            let data = {};
            if (did.i_account) data.i_account_old = did.i_account;
            if (this.newTrunkId) data.i_master_account = this.newTrunkId;

            if(did.ipnd_reqirement){
              data.ipnd_generic_values = this.selectedIpnds[did.i_did_number];
            }

            await this.$nextTick();
            this.Api.setHttpObject({timeout:60000});
            let response = await this.Api.send('post',
                'dids/' + did.number + '/transfer',
                data
            );
            this.Api.setHttpObject({timeout:20000});
            if (response.success) {
                return { success: true, message: 'DID moved successfully' };
            } else {
                return { success: false, message: response.errorDetail };
            }
        },
        moveDIDs: function () {
            this.busy = true;
            const maxNumOfWorkers = 1;
            var numOfWorkers = 0;
            var taskIndex = 0;
            return new Promise((done) => {
                const handleResult = (index) => (result) => {
                    this.didListLocal[index].busy = false;
                    this.didListLocal[index].status = result.message;
                    if (result.success) {
                        this.didListLocal[index].success = true;
                    } else {
                        this.didListLocal[index].error = true;
                    }
                    numOfWorkers--;
                    this.tasksComplete++;
                    getNextTask();
                };
                const getNextTask = () => {
                    if (
                        numOfWorkers < maxNumOfWorkers &&
                        taskIndex < this.didListLocal.length
                    ) {
                        this.didListLocal[taskIndex].status = 'Please wait...';
                        this.didListLocal[taskIndex].busy = true;
                        this.moveDID(this.didListLocal[taskIndex])
                            .then(handleResult(taskIndex))
                            .catch(handleResult(taskIndex));
                        taskIndex++;
                        numOfWorkers++;
                        getNextTask();
                    } else if (
                        numOfWorkers === 0 &&
                        taskIndex === this.didListLocal.length
                    ) {
                        taskIndex++;
                      document.dispatchEvent(new CustomEvent('refreshRequested'));
                      this.busy = false;
                        done();
                    }
                };
                getNextTask();
            });
        },
    },
    watch: {
        transferType: function (val) {
            if (val !== 1) {
                this.carrier = null;
                this.accountNumber = null;
            }
            if (val !== 2) {
                this.migrationType = null;
            }
            this.editCustomerDetail = null;
        },
        visible: function (value) {
            this.reset( );
        },
    },
    props: {
        title: {
            type: String,
            default: 'Transfer DIDs',
        },
        customerId: {
            type: Number,
            default: null,
        },
        trunkData: {
            type: Object,
            default: null,
        },
        didList: {
            default: null,
        },
    },
    computed: {
        taskProgress: function () {
            if (!this.didListLocal.length) {
                return 0;
            }
            return (this.tasksComplete / this.didListLocal.length) * 100;
        },
        trunkId: function ( ){
          if(this.trunkData == null){
            return null;
          }else{
            return this.trunkData.i_account;
          }
        },
        /*
        If the IPND requirements for the DIDs being moved at satisfied.
         */
        newTrunkIpndSatisfied: function( ){
          let ret = true;
          this.didListLocal.forEach((item) => {
            if(item.ipnd_reqirement && this.selectedIpnds[item.i_did_number] == null){
              ret = false;
              return;
            }
          });
          return ret;
        },
        // Check to see if we have at least 1 selected checkbox. If so it converts to the group editing process.
        // Note that all dids are loaded already selected.
        haveASelectedDid() {
          return Object.keys(this.selectedDids).length > 0;
        },
        allDidsSelected( ){
          let length = Object.keys(this.selectedDids).length;
          return length == this.didListLocal.length;
        },
        selectedDids( ){
          let selected = {};
          this.didListLocal.forEach((item) => {
            if(item.selected){
              selected[item.i_did_number] = item;
            }
          });
          return selected;
        },
        selectableIpndList( ){
          let ret = [];
          if(this.newTrunkData && this.newTrunkData.ipnd_list){
            ret = this.newTrunkData.ipnd_list;
          }
          //we now need to check other new IPND assigned to DID that are newly created
          Object.keys(this.selectedIpnds).forEach((key) => {
            // We use the hash value to check.
            let found = null;
            ret.forEach((selectableIpnd)=>{
              if(selectableIpnd.hash == this.selectedIpnds[key].hash){
                found = selectableIpnd;
                return;
              }
            })
            if(found == null){
              ret.push(this.selectedIpnds[key]);
            }
          });
          return ret;
        }
    },
};
</script>

<style scoped></style>
