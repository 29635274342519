<template>
    <v-row dense>
        <v-col cols="12" v-if="customerData.status === 'suspended'">
            <v-alert class="mb-0" type="warning">Customer is suspended due to non-payment!</v-alert>
        </v-col>
        <v-col cols="12" v-else-if="customerData.status === 'credit_exceeded'">
            <v-alert class="mb-0" type="warning">Customer has exceeded credit limit!</v-alert>
        </v-col>
        <!-- non-dismissable alert only shows if status is blocked -->
        <v-col cols="12" v-else-if="customerData.status === 'blocked'">
            <PageAlert
                :loading="customerLoading"
                type="error"
                button
                button-text="Unblock now"
                @click="unblockDialog = true"
            >
                This customer account is blocked.<span v-if="$store.state.user.isNotAdmin"> Please contact Breeze Connect for more information.</span>
            </PageAlert>
        </v-col>


        <!-- non-dismissable alert only shows if status is closed -->
        <v-col cols="12" v-else-if="customerData.status === 'closed'">
            <PageAlert
                    :loading="customerLoading"
                    type="error"
            >
                This account is closed.
            </PageAlert>
        </v-col>
        <v-col cols="12" v-else-if="customerData.terminate_on">
            <PageAlert
                    :loading="customerLoading"
                    type="warning"
            >
                Customer account is pending termination. Account due to
                be terminated
                <LocalDateTime :value="customerData.terminate_on" relative />.
            </PageAlert>
        </v-col>
        <v-col cols="12" v-else-if="customerData.status === 'suspension_delayed'">
            <PageAlert
                    :loading="customerLoading"
                    type="info"
            >
                Customer account is overdue and extension has been applied.<br/>
                Suspension has been delayed until <LocalDateTime :value="customerData.suspension_delay_date" relative />.
            </PageAlert>
        </v-col>
        <v-col cols="12" v-else-if="!!customerData && (customerData.credit_limit * 0.8)<customerData.balance">
            <PageAlert
                :loading="customerLoading"
                type="info"
            >
                Customer has consumed more than 80% of credit limit!
            </PageAlert>
        </v-col>
        <v-col cols="12" v-if="customerData.i_parent">
            <v-alert
                type="info"
                class="mb-0"
            >
                This customer is managed by a reseller account.
                Reseller accounts are not fully supported yet in this portal!
            </v-alert>
        </v-col>

        <!-- Customer Details Card -->
        <v-col cols="12" sm="6" lg="4">
            <CustomerCard
                :data="customerData"
                :loading="customerLoading"
            />
        </v-col>
        <v-col cols="12" sm="6" lg="4">
            <CustomerAccountCard :data="customerData" :loading="customerLoading" />
        </v-col>
        <v-col cols="12" sm="6" lg="4">
            <CustomerBillingCard :data="customerData" :loading="customerLoading" />
        </v-col>
        <v-col cols="12" sm="6" lg="4">
            <AddressCard :data="customerData" :loading="customerLoading" @changeAddress="changeAddress()"/>
        </v-col>
        <v-col cols="12" sm="6" lg="4">
            <PrimaryContactCard title="Primary Contact Details" :data="customerData" :loading="customerLoading" @editDetails="editPrimaryContactDetails()"/>
        </v-col>
        <v-col cols="12" sm="6" lg="4">
            <CustomerLoginCard :data="customerData" :loading="customerLoading" />
        </v-col>
        <v-col cols="12" sm="6" lg="4">
          <CustomerDefaultCallerIDCard :customerData="customerData" :loading="customerLoading" />
        </v-col>
        <v-col cols="12" sm="6" lg="4">
          <CustomerVoiceCallsConfigurationCard :customerData="customerData" :loading="customerLoading" />
        </v-col>
      <v-col cols="12" sm="6" lg="4">
        <CustomerNBNCard :customerData="customerData" :loading="customerLoading" />
      </v-col>
      <UpdateAddressDialog @address-selected="addressSelected" v-model="changeAddressDialog" :customerId="customerData.i_customer" typeSwitch="customer"/>
      <UpdatePrimaryContactDetailsDialog v-model="changePrimaryContactDialog" :customerId="customerData.i_customer"/>
    </v-row>
</template>

<script>
import PageAlert from '../../components/core/PageAlert';
import LocalDateTime from '../../components/pieces/LocalDateTime';
import CustomerCard from "../../components/cards/Customer/CustomerCard";
import AddressCard from "../../components/cards/AddressCard";
import CustomerAccountCard from '@/components/cards/Customer/CustomerAccountCard';
import CustomerLoginCard from '../../components/cards/Customer/CustomerLoginCard';
import CustomerBillingCard from '../../components/cards/Customer/CustomerBillingCard';
import customerDataMixin from '../../mixins/customerDataMixin';
import UpdateAddressDialog from '../../components/dialogs/Addresses/UpdateAddressDialog';
import PrimaryContactCard from "../../components/cards/PrimaryContactCard";
import UpdatePrimaryContactDetailsDialog from "../../components/dialogs/Customer/UpdatePrimaryContactDetailsDialog.vue";
import CustomerDefaultCallerIDCard from "../../components/cards/Customer/CustomerDefaultCallerIDCard";
import CustomerVoiceCallsConfigurationCard from "../../components/cards/Customer/CustomerVoiceCallsConfigurationCard";
import CustomerNBNCard from "../../components/cards/Customer/CustomerNBNCard";
export default {
    name: 'CustomerSummary',
    data: () => ({
      changeAddressDialog:false,
      changePrimaryContactDialog: false,
    }),
    mixins: [customerDataMixin],
    components: {
      CustomerNBNCard,
      CustomerVoiceCallsConfigurationCard,
      CustomerDefaultCallerIDCard,
        PageAlert,
        LocalDateTime,
        CustomerBillingCard,
        CustomerLoginCard,
        CustomerAccountCard,
        AddressCard,
        CustomerCard,
        UpdateAddressDialog,
        PrimaryContactCard,
        UpdatePrimaryContactDetailsDialog
    },
    methods: {
      changeAddress( ){
        this.changeAddressDialog = true;
      },
      editPrimaryContactDetails( ){
        this.changePrimaryContactDialog = true;
      },
      addressSelected( ){
        document.dispatchEvent(new CustomEvent('refreshRequested'));
      }
    },

};
</script>