<template>
    <div>
        <v-card v-if="showFilters" class="pa-2 mb-2">

            <v-card-title style="padding-top:4px;padding-bottom:8px; padding-left:0px; padding-right:0px;">Emergency Services Addresses</v-card-title>

            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  hide-details
                  outlined
                  clearable
                  dense
                  label="Keyword"
                  :disabled="isBusy"
                  v-model="filters.keyword"
                />
              </v-col>
              <v-col cols="6" sm="2">
                <v-btn
                  outlined
                  block
                  height="40"
                  :loading="isBusy"
                  @click="filterResults"
                >Search</v-btn>
              </v-col>
            </v-row>
        </v-card>

      <IPNDDataTableListComponent :ipnd-list="items" :porta-account-data="portaAccountData" @updateIpndList="updateIpndList"/>
    </div>
</template>

<script>
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
import apiMixin from '../../../mixins/apiMixin';
import IPNDDataTableListComponent from "../../pieces/IPND/IPNDDataTableListComponent.vue";

export default {
    name: 'TrunkIPNDListCard',
    mixins: [apiMixin, refreshListenerMixin],
    data: () => ({
      busy: false,
      showFilters: true,
      items: [],
      filters: {
        "keyword":null
      },

        // localFilter: {
        //     id: null,
        //     active: 'Y',
        //     note:null,
        // },
        // statusOptions: [
        //     { option: 'Active', value: 'Y' },
        //     { option: 'Terminated', value: 'N' },
        // ],
    }),
    components: {
      IPNDDataTableListComponent

    },
    props: {
        portaAccountData:{
          type: Object,
          default: null
        },
        loading:{
          type: Boolean,
          default: false
        }
    },
    methods: {
        updateIpndList( ){
          this.filterResults( );
        },
        async filterResults() {
          this.busy = true;
          let params = { };
          if(this.filters.keyword != null){
            params.keyword = this.filters.keyword
          }

          let queryString = Object.keys(params).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
          }).join('&');

          if(queryString != null && queryString != ''){
            queryString = '?'+queryString;
          }

          const response = await this.Api.send('get','trunks/'+this.portaAccountData.i_account+'/emergency-services-addresses'+queryString);
          this.busy = false;
          if (response.success) {
            this.items = response.data;
          } else {
            this.items = [];
          }
        },
    },
    watch: {
      portaAccountData(value, previous) {
        if(value == null || value.i_account == null){
          this.items = [];
        }else{
          if(value.i_account != previous.i_account){
            this.filterResults( );
          }
        }
      },
    },
    created( ){
      if(this.portaAccountData != null && this.portaAccountData.i_account) {
        this.filterResults();
      }
    },
    computed:{
      isBusy: function( ){
        return this.loading || this.busy;
      },
      showIpndIDFilter: function( ){
        return this.$store.state.user.isAdmin;
      },

    }
};
</script>
