<template>
    <div>
        <v-card v-if="showFilters" class="pa-2 mb-2">
            <v-row>
                <v-col cols="12" :sm="3">
                    <v-text-field
                        hide-details
                        outlined
                        clearable
                        dense
                        label="Number"
                        :disabled="isBusy"
                        v-model="searchFilters.number"
                        @keydown.enter="update"
                        @change="updateDID"
                    />
                </v-col>
              <v-col cols="12" :sm="3">
                <v-autocomplete
                    hide-details
                    outlined
                    clearable
                    dense
                    :items="descriptionOptionsArray"
                    :disabled="isBusy"
                    label="Description/SZU"
                    v-model="searchFilters.description"
                    :value="searchFilters.description"
                    placeholder="Type to search"
                    hide-no-data
                    :return-object="false"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-select
                    v-if="false"
                    hide-details
                    outlined
                    dense
                    label="Area code"
                    v-model="searchFilters.areaCode"
                    :disabled="isBusy"
                    :items="areaCodeOptions"
                    item-text="option"
                    item-value="value"
                />
                <v-select
                    hide-details
                    outlined
                    dense
                    label="State"
                    v-model="searchFilters.state"
                    :disabled="isBusy"
                    :items="stateOptions"
                    item-text="option"
                    item-value="value"
                />
              </v-col>
                <v-col cols="12" sm="3" v-if="statusValuePerm == null">
                    <v-select
                        v-if="statusValuePerm == null"
                        outlined
                        dense
                        label="Status"
                        v-model="searchFilters.usage"
                        :disabled="isBusy"
                        :items="usageOptions"
                        item-text="option"
                        item-value="value"
                    />
                </v-col>
                <v-col cols="12" sm="3">
                    <v-btn
                        outlined
                        block
                        height="40"
                        :loading="isBusy"
                        @click="update"
                    >Search</v-btn>
                </v-col>
            </v-row>
        </v-card>
        <PaginationCardV2
          :page="searchFilters.page"
          :totalRecords="listData.count"
          :totalPages="listData.pages"
          :limit="searchFilters.limit"
          :currentPageTotal="(listData.data) ? listData.data.length : 0"
          :loading="isBusy"
          @updatedPagination="updatedPagination"
          :no-skeleton="false"
        >
            <template v-slot:content v-if="$slots.content">
                <slot name="content" />
            </template>

            <v-toolbar
                dense
                fluid
                v-if="selected.length"
                :color="$vuetify.theme.dark ? 'blue-grey' : 'blue lighten-3'"
                flat
            >
                <v-btn icon @click="changeSelectAll(false)">
                    <v-icon>mdi-close</v-icon>
                </v-btn>

                <v-toolbar-title
                    >{{ selected.length }} DID<span v-if="selected.length > 1"
                        >s</span
                    >
                    selected</v-toolbar-title
                >

            </v-toolbar>
            <v-simple-table>
                <thead>
                    <tr v-show="!selected.length">
                        <th >
                            <v-simple-checkbox
                                v-if="listData.data && listData.data.length"
                                v-model="selectAll"
                                @input="changeSelectAll"
                                dense
                            />
                        </th>
                        <th>Number</th>
                        <th v-if="statusValuePerm == null">Status</th>
                        <th>State</th>
                        <th>Description / SZU</th>
                        <th class="text-center">Periodic Cost</th>
                        <th class="text-center" v-if="$store.state.user.user_type === 'admin'">Carrier</th>
                        <th class="text-center" v-if="$store.state.user.user_type === 'admin'">Carrier Status</th>
                    </tr>
                </thead>
                <!--tbody -->

                <v-slide-x-transition tag="tbody" hide-on-leave group>
                    <tr v-if="listData.data && listData.data.length === 0" key="nodata">
                        <td colspan="100%" class="text-center">
                            No DIDs found
                        </td>
                    </tr>
                    <tr
                        v-for="item in listData.data"
                        :key="item.i_did_number"
                        v-bind:class="{
                            'blue-grey darken-2':
                                $vuetify.theme.dark && item.selected,
                            'blue lighten-5': !$vuetify.theme.dark && item.selected,
                        }"
                    >
                        <td>
                            <v-simple-checkbox
                                dense
                                v-model="item.selected"
                                :disabled="disabled"
                                @input="updateSelected"
                            />
                        </td>

                        <td class="pa-0">
                            <router-link
                                v-if="$store.state.user.isAdmin"
                                :to="{name: 'DIDDetail', params: { did: item.hasOwnProperty('number') ? item.number : item.did_number }}"
                                style="cursor: pointer"
                                class="pa-4"
                            >
                                <span class="font-weight-medium">{{
                                    item.hasOwnProperty('number') ? item.number : item.did_number
                                }}</span>
                            </router-link>
                            <span v-else class="font-weight-medium">{{item.hasOwnProperty('number') ? item.number : item.did_number}}</span>
                        </td>

                        <td v-if="statusValuePerm == null">
                            <DIDStatus :data="item" />
                        </td>
                        <td>{{ item.hasOwnProperty('city_name') ? item.city_name : item.area_name }}</td>
                        <td>{{ item.description }}</td>
                        <td class="text-center">
                            <Currency :value="item.hasOwnProperty('periodic_fee') ? item.periodic_fee : item.pricing.customer_costs.periodic" add-gst />
                        </td>
                        <td class="text-center" v-if="$store.state.user.user_type === 'admin'">
                            <span v-if="item.vendor_tag == 'auswide'">Auswide</span>
                            <span v-if="item.vendor_tag == 'net-sip'">NetSIP</span>
                            <span v-if="item.vendor_tag == 'vx-group'">VX</span>
                        </td>
                        <td class="text-center" v-if="$store.state.user.user_type === 'admin'">
                            <DIDCarrierStatus small :status="item.carrier_status" />
                        </td>
                    </tr>
                </v-slide-x-transition>
                <!-- /tbody -->
            </v-simple-table>
        </PaginationCardV2>
    </div>
</template>

<script>
import PaginationCardV2 from "../../cards/templates/PaginationCardV2.vue";
import Currency from '../../pieces/Currency';
import DIDStatus from '../../pieces/DID/DIDStatus';
import DIDCarrierStatus from '../../pieces/DID/DIDCarrierStatus';
import apiMixin from '../../../mixins/apiMixin';

export default {
    name: 'DIDPoolSelect',
    mixins: [apiMixin],
    data: () => ({
        busy:false,
        searchFilters: {
          number: '',
          usage: 'A',
          state: 'any',
          areaCode:'any',
          description: '',
          page:1,
          limit: 10,
        },
        listData: {
          total_records:0,
          total_pages:0,
          results: []
        },
        // localFilter: {
        //     number: '',
        //     usage: 'A',
        //     state: 'any',
        //     areaCode:'any',
        //     description: '',
        // },
        usageOptions: [
            { option: 'All', value: 'A' },
            { option: 'Unused', value: 'I' },
            { option: 'Unused+Res', value: 'F' },
            { option: 'In Use', value: 'U' },
            { option: 'Frozen', value: 'Z' },
        ],
        areaCodeOptions: [
          { option: 'Any', value: 'any' },
          { option: 'NSW/ACT (02)', value: '02' },
          { option: 'VIC/TAS (03)', value: '03' },
          { option: 'Mobile (04)', value: '04' },
          { option: 'QLD (07)', value: '07' },
          { option: 'WA,SA,NT (08)', value: '08' },
        ],
        stateOptions: [
          { option: 'Any', value: 'any' },
          { option: 'NSW', value: 'New South Wales' },
          { option: 'VIC', value: 'Victoria' },
          { option: 'QLD', value: 'Queensland' },
          { option: 'WA', value: 'Western Australia' },
          { option: 'SA', value: 'South Australia' },
          { option: 'NT', value: 'Northern Territory' },
          { option: 'ACT', value: 'Australian Capital Territory' },
          { option: 'TAS', value: 'Tasmania' },
        ],
        descriptionOptions: [],
        selectAll: false,
        transferDIDDialog: {
            show: false,
        },
        removeDIDDialog: {
            show: false,
        },
        selected: [],
        table: {
            headers: [
                {
                    text: 'Number',
                    align: 'start',
                    sortable: false,
                    value: 'number',
                },
                { text: 'City Name', value: 'city_name' },
                { text: 'Description', value: 'description' },
            ],
        },
    }),
    components: {
        DIDCarrierStatus,
        DIDStatus,
        Currency,
        PaginationCardV2,
    },
    props: {
        showFilters: {
          type:Boolean,
          default:true,
        },
        loading:{
          type: Boolean,
          default:false,
        },
        hidePagination: {
            type: Boolean,
            default: false,
        },
        acctId: {
            type: Number,
            default: null,
        },
        custId: {
            type: Number,
            default: null,
        },
        reload: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        statusValuePerm: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
      isBusy: function( ){
        return this.loading || this.busy;
      },
      descriptionSelectOptions: function( ){
        let ret = [];
        this.descriptionOptions.forEach(item => {
          ret.push({
            "option":item.numbering_area,
            "value":item.numbering_area
          })
        });
        return ret;
      },
      descriptionOptionsArray: function( ){
        let ret = [];
        this.descriptionOptions.forEach(item => {
          ret.push(item.numbering_area);
        });
        return ret;
      }
    },
    methods: {
        updatedPagination(data){
          this.searchFilters.page = data.page;
          this.searchFilters.limit = data.limit;
          this.update( );
        },
        updateSearch(data){
          this.searchFilters.page = 1;
          this.update( );
        },
        refresh() {
            document.dispatchEvent(new CustomEvent('refreshRequested'));
        },
        async update() {
            this.busy = true;
            let params = { ...this.searchFilters };
            params.i_customer = this.custId;
            params.i_account = this.acctId;

            if(this.statusValuePerm != null){
              params.usage = this.statusValuePerm;
            }else{
              if (this.searchFilters.usage) params.usage = this.searchFilters.usage;
            }

            for (const [key, value] of Object.entries(params)) {
              if(value == null || value == undefined){
                delete params[key];
              }
            }

            if(params.areaCode == 'any'){
              delete params.areaCode;
            }

            if(params.state == 'any'){
              delete params.state;
            }else{
              params.cityName = params.state;
              delete params.state;
            }

            const response = await this.Api.send('post','dids/list', params);

            this.selected = [];
            this.selectAll = false;
            this.busy = false;
            this.firstLoad = false;

            if (response.success) {
              this.listData = response.data;
            } else {
              this.listData = [];
            }
        },
        updateStatus(data) {
            this.$emit('update', { status: data, page: 1 })
        },
        updateSelected() {
            this.selected = this.listData.data.filter((x) => x.selected);
            this.$emit('input', this.selected);
        },
        changeSelectAll(value) {
            this.listData.data.forEach((item) => {
                item.selected = value;
            });
            this.selectAll = value;
            this.updateSelected();
        },
        updateDID() {
            if (!!this.searchFilters.number) {
                if (this.searchFilters.number.includes(' ')) this.searchFilters.number = this.searchFilters.number.replaceAll(' ', '');
            }
        }
    },
  async created( ){
    const response = await this.Api.send('get','resources/unique-did-descriptions');
    this.descriptionOptions = response.data;
  },

};
</script>
