<template>
    <v-row dense>
        <v-col cols="12" v-if="trunkData.i_account">
            <DIDListCard
                v-if="trunkData.bill_status !== 'C'"
                :trunkData="trunkData"
                :cust-id="trunkData.i_customer"
                :loading="trunkLoading"
                :filter="filter"
                @update="updateFilter"
                :addFunction="openAddDialog"
                hide-status
                show-tools
                show-filters
            />
        </v-col>
      <FloatingButton tooltip="Add New DIDs" icon="mdi-phone-plus" @click="openAddDialog" :loading="trunkLoading" />
      <AddDIDDialogTypeSelect v-model="showDialogTypeSelect" :loading="trunkLoading" v-if="$store.state.user.isAdmin" @selected="openDialog" />
      <AddDIDDialogFromPool v-model="showDialogFromPool" :trunkData="trunkData" :customer-id="trunkData.i_customer" processTypeSwitch="trunk"/>
      <AddDIDDialogFromManualEntry v-if="$store.state.user.isAdmin" v-model="showDialogFromManualEntry" :trunkData="trunkData"  :customer-id="trunkData.i_customer" processTypeSwitch="trunk"/>


    </v-row>
</template>

<script>
import DIDListCard from '../../components/cards/DID/DIDListCard';
import filterMixin from '../../mixins/filterMixin';
import trunkDataMixin from '../../mixins/trunkDataMixin';
import FloatingButton from '@/components/pieces/FloatingButton';
import AddDIDDialogTypeSelect from "../../components/dialogs/DID/AddDIDDialogTypeSelect.vue";
import AddDIDDialogFromManualEntry from "../../components/dialogs/DID/AddDIDDialogFromManualEntry.vue";
import AddDIDDialogFromPool from "../../components/dialogs/DID/AddDIDDialogFromPool.vue";
export default {
    name: 'TrunkDIDs',
    mixins: [filterMixin, trunkDataMixin],
    components: {
      AddDIDDialogFromPool, AddDIDDialogFromManualEntry, AddDIDDialogTypeSelect,
      FloatingButton,
      DIDListCard,
    },
    data: () => ({
        filter: {
            usage: 'A',
            number: null,
            page: 1,
            limit: 10,
        },
      showDialogTypeSelect: false,
      showDialogFromPool: false,
      showDialogFromCarrier: false,
      showDialogFromManualEntry: false,
        // addDidOptions: {"admin":[1,2,3],"partner":[1]}
    }),
    beforeRouteLeave(to, from, next){
        return (this.showNewDIDs) ? next(false) : next();
    },
    methods: {
      openAddDialog: function( ){
        if(this.$store.state.user.isAdmin){
          this.showDialogTypeSelect=true
        }else{
          this.showDialogFromPool=true;
        }
      },

      openDialog: function(val){
        if(val == 1) {
          this.showDialogFromPool = true;
        }else if(val == 3){
          this.showDialogFromManualEntry = true;
        }
      },
    }
};
</script>

<style scoped></style>
