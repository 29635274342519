<template>
    <v-row dense v-if="customerData">
        <v-col cols="12">
            <DIDListCard
                :cust-id="customerData.i_customer"
                :loading="customerLoading"
                :filter="filter"
                @update="updateFilter"
                show-filters
                show-tools
                :addFunction="openAddDialog"
            />
        </v-col>
      <FloatingButton v-if="customerData && customerData.status != 'closed' && ($store.state.user.isAdmin || $store.state.user.isPartner)" tooltip="Add New DIDs" icon="mdi-phone-plus" @click="openAddDialog" :loading="customerLoading" />
      <AddDIDDialogTypeSelect v-model="showDialogTypeSelect" :loading="this.customerLoading" v-if="$store.state.user.isAdmin" @selected="openDialog" />
      <AddDIDDialogFromPool v-model="showDialogFromPool" process-type-switch="customer" :customer-id="customerId" />
      <AddDIDDialogFromManualEntry v-if="$store.state.user.isAdmin" v-model="showDialogFromManualEntry" process-type-switch="customer" :customer-id="customerId" />
    </v-row>
</template>
<script>
import DIDListCard from '../../components/cards/DID/DIDListCard';
import filterMixin from '../../mixins/filterMixin';
import customerDataMixin from '../../mixins/customerDataMixin';
import FloatingButton from '@/components/pieces/FloatingButton';
import AddDIDDialogTypeSelect from "../../components/dialogs/DID/AddDIDDialogTypeSelect.vue";
import AddDIDDialogFromPool from "../../components/dialogs/DID/AddDIDDialogFromPool.vue";
import AddDIDDialogFromManualEntry from "../../components/dialogs/DID/AddDIDDialogFromManualEntry.vue";
export default {
    name: 'CustomerDIDs',
    mixins: [filterMixin, customerDataMixin],
    components: {
      AddDIDDialogFromManualEntry,
      AddDIDDialogFromPool,
      AddDIDDialogTypeSelect,
      FloatingButton,
        DIDListCard,
    },
    data: () => ({
        filter: {
            usage: 'A',
            number: null,
            page: 1,
            limit: 10,
        },
        showDialogTypeSelect: false,
        showDialogFromPool: false,
        showDialogFromCarrier: false,
        showDialogFromManualEntry: false,
        // addDidOptions: {"admin":[1,2,3],"partner":[1]}
    }),
    beforeRouteLeave(to, from, next){
        return (this.showNewDIDs) ? next(false) : next();
    },
    methods:{
      openDialog: function(val){
        if(val == 1) {
          this.showDialogFromPool = true;
        }else if(val == 2){
          this.showDialogFromCarrier = true;
        }else if(val == 3){
          this.showDialogFromManualEntry = true;
        }
      },
      openAddDialog: function(val){
        if(this.$store.state.user.isAdmin){
          this.showDialogTypeSelect=true;
        }else{
          this.showDialogFromPool=true;
        }
      }
    }
};
</script>
